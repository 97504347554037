import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

export function ErrorPage() {
  const error = useRouteError() as Error;

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <main className="mx-auto mt-10 flex w-11/12 flex-1 flex-col items-center justify-center rounded-md bg-white/[.9] px-20 pb-4 shadow-lg md:w-6/12">
      <article className="min-w-full pb-10">
        <h1 className="text-center text-2xl text-red-700">
          Surgio un error en la página.
        </h1>
        <h2 className="mt-2 text-center text-red-400">
          {error.message ||
            "Hubo un error inesperado en la página, contacte con el administrador."}
        </h2>
        <img
          className="mt-5"
          src="https://media3.giphy.com/media/FYUnDtud95kMKCovAY/giphy.gif?cid=ecf05e47pn5b5mla06j4rrry2d350q89umkidcon3gdr7ay1&ep=v1_gifs_search&rid=giphy.gif&ct=g"
          alt="Error"
        />
        <p className="mt-9 text-center">
          Por favor contacte con el administrador de la página
        </p>
      </article>
    </main>
  );
}
