import { useField, useFormikContext } from "formik";
import { cfdiUse, paymentMethods, rfcRegex } from "../../shared/constants";
import { getCustomerInformation } from "../../services/customers";
import { RegisterFormValues } from "../../types/customer";

export function SearchTextInput({
  label,
  type,
  setAlert,
  setLoading,
  setIsNewCustomer,
  ...props
}) {
  const { setFieldValue, initialValues } = useFormikContext();

  const [field, meta, helpers] = useField(props.name);

  const { setValue } = helpers;

  const handleChange = (e) => {
    const value = e.target.value.toUpperCase();
    setValue(value);

    if (rfcRegex.test(value)) {
      setLoading(true);
      getCustomerInformation(value)
        .then((data) => {
          if (data) {
            Object.keys(data).forEach((key) => {
              if (key === "cfdiUseCode") {
                setFieldValue(key, cfdiUse[data[key]]);
              }

              if (key === "paymentMethod") {
                setFieldValue(key, paymentMethods[data[key]]);
              }

              setFieldValue(key, data[key]?.toString().toUpperCase() || "");
            });
            setIsNewCustomer(() => false);
            setAlert(() => ({
              open: true,
              message: "Verifica si tus datos son correctos",
              type: "warning",
            }));
          } else {
            // Con esto volvemos a regresar al estado inicial el form excepto el RFC
            for (const key in initialValues as RegisterFormValues) {
              if (key !== "rfc") {
                setFieldValue(
                  key,
                  (initialValues as any)[key]?.toString().toUpperCase() || "",
                );
              }
            }
            setIsNewCustomer(() => true);
            setAlert(() => ({
              open: false,
              message: "Verifica si tus datos son correctos",
              type: "warning",
            }));
          }
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 400);
        });
    }
  };

  // useEffect(() => {

  // }, [field.value, setFieldValue, setIsNewCustomer, setAlert, setLoading]);

  return (
    <>
      <label
        className={"block text-sm font-medium text-gray-700 "}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <input
        {...field}
        className={`block w-full rounded-md border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${
          meta.touched && meta.error
            ? "border-2 border-red-600 outline-1 outline-red-600"
            : null
        }`}
        type={type}
        onChange={handleChange}
        {...props}
      />
      {meta.touched && meta.error ? (
        <span className="m-0 text-sm text-red-600">{meta.error}</span>
      ) : null}
    </>
  );
}
