import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useFormikContext } from "formik";

export function ConfirmationFormDialog({
  initialOpen,
  setOpen,
  title,
  message,
}) {
  const formik = useFormikContext();

  const handleClose = (event, reason?: string) => {
    if (event && reason && reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const handleSend = () => {
    formik.handleSubmit();
    setOpen(false);
  };

  return (
    <Dialog
      open={initialOpen}
      disableEscapeKeyDown
      keepMounted
      disableScrollLock
      onClose={handleClose}
      aria-labelledby="confirmation-form-dialog-title"
      aria-describedby="confirmation-form-dialog-description"
    >
      <DialogTitle id="confirmation-form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-form-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={handleSend} autoFocus>
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
