import * as Yup from "yup";
import { rfcRegex } from "../../shared/constants";

export const RegisterSchema = Yup.object().shape({
  rfc: Yup.string()
    .matches(
      rfcRegex,
      "RFC no válido. Verifica que no contenga espacios o caracteres especiales",
    )
    .min(12, "RFC mínimo de 12 caracteres")
    .max(13, "RFC máximo 13 caracteres")
    .trim()
    .required("RFC es requerido"),
  name: Yup.string()
    .min(1, "Nombre/Razón social es requerido")
    .trim()
    .required("Nombre/Razón social es requerido"),
  email: Yup.string()
    .min(5, "Email es requerido")
    .trim()
    .email("Debe ser un email válido")
    .required("Correo electrónico es requerido"),
  street: Yup.string()
    .min(3, "Calle debe tener minimo 3 caracteres")
    .trim()
    .required("Calle es requerida"),
  interiorNumber: Yup.string()
    .optional()
    .typeError("Debe ser un número válido"),
  exteriorNumber: Yup.number()
    .required("Número exterior es requerido")
    .typeError("Debe ser un número válido"),
  zipCode: Yup.string()
    .trim()
    .min(4, "Código postal debe tener mínimo 4 caracteres")
    .required("Código postal es requerido"),
  locality: Yup.string()
    .trim()
    .min(3, "Colonia debe tener mínimo 3 caracteres")
    .required("Colonia es requerido"),
  municipality: Yup.string()
    .trim()
    .min(3, "Municipio debe tener mínimo 3 caracteres")
    .required("Municipio es requerido"),
  state: Yup.string().trim().required("Estado es requerido"),
  cfdiUseCode: Yup.string().trim().required("Uso de CFDI es requerido"),
  paymentMethod: Yup.string().trim().required("Método de pago es requerido"),
  ticket: Yup.mixed()
    .required("Ticket es requerido")
    .test(
      "FILE_SIZE",
      "Ticket debe tener un tamaño máximo de 3MB",
      (value: { size: number }) => value.size <= 3 * 1024 * 1024,
    ),
  cedula: Yup.mixed().test(
    "SIZE",
    "Cedula debe tener un tamaño máximo de 3MB",
    (value: { size: number }) => !value || value.size <= 3 * 1024 * 1024,
  ),

  ticketNumber: Yup.number()
    .integer("El numero del ticket no es válido")
    .min(1000, "Numero de ticket no válido")
    .required("Número de ticket es requerido"),
  regimenFiscal: Yup.string().trim().required("Régimen fiscal es requerido"),
  cajaId: Yup.string().trim().required("Caja es requerido"),
});
