export interface RegisterFormValues {
  rfc: string;
  name: string;
  email: string;
  street: string;
  interiorNumber: string;
  exteriorNumber: string;
  zipCode: string;
  locality: string;
  municipality: string;
  state: string;
  cfdiUseCode: string;
  paymentMethod: string;
  cedula?: string | File;
  ticket?: string | File;
  ticketNumber: string;
  regimenFiscal: string;
  cajaId: string;
  ticketDate: Date | string;
}

export const FormInitialValues: RegisterFormValues = {
  rfc: "",
  name: "",
  email: "",
  street: "",
  interiorNumber: "",
  exteriorNumber: "",
  zipCode: "",
  locality: "",
  municipality: "",
  state: "",
  cfdiUseCode: "",
  paymentMethod: "",
  ticket: undefined,
  cedula: undefined,
  ticketDate: new Date(),
  ticketNumber: "",
  regimenFiscal: "",
  cajaId: "",
};
