import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/index.tsx";
import React from "react";
import "./assets/css/index.css";

Sentry.init({
  dsn: "https://7ea75c0ce6aa1098965a498df5cc48fc@o4506769789747200.ingest.us.sentry.io/4507924569784320",
  integrations: [Sentry.httpClientIntegration()],
});

ReactDOM.createRoot(document.getElementById("app")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
