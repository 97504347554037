import { createBrowserRouter } from "react-router-dom";
import { Welcome } from "../pages/Welcome";
import { CommonLayout } from "../layout/CommonLayout";
import { Register } from "../pages/Customer/Register";
import { ErrorPage } from "../pages/ErrorPage/Index";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const router = createBrowserRouter([
  {
    path: "/",

    element: (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CommonLayout />
      </LocalizationProvider>
    ),
    children: [
      {
        path: "/",
        element: <Welcome />,
      },
      {
        path: "/clientes/registro",
        element: <Register />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);
