import { Link, Outlet } from "react-router-dom";
import { FaMapPin, FaPhoneAlt } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import TitleLogo from "../assets/logo.png";

export function CommonLayout() {
  const year = new Date().getFullYear();
  return (
    <>
      <main className="mx-auto mt-10 flex w-11/12 flex-1 flex-col items-center justify-center rounded-md bg-white/[.9] px-20 pb-4 shadow-lg lg:w-6/12">
        <Link
          to="/"
          className="overflow-hidden rounded-full md:w-64 xl:w-80 2xl:w-96"
        >
          <h1 className="sr-only">Dulcería El Conejo Feliz</h1>
          <img
            className="w-full"
            src={TitleLogo}
            alt="Dulceria El conejo Feliz"
          />
        </Link>

        <Outlet />
      </main>

      <footer className="mt-20 min-w-full bg-title px-3 py-4">
        <div className="container mx-auto flex flex-col items-center gap-3 md:flex-row md:content-center md:justify-between">
          {/* Información de la empresa */}
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-bold text-gray-600">
              Dulcería El Conejo Feliz
            </h3>
            <a
              href="https://maps.app.goo.gl/u6wokmVaVeAAFR7f6"
              className="flex text-gray-600"
            >
              <FaMapPin />
              Av 3a. Sur Pte 548, Centro, 29000 Tuxtla Gutiérrez, Chis.
            </a>
            <a
              href="tel:+529616142800"
              className="flex items-center justify-between text-gray-600"
            >
              <FaPhoneAlt />
              961 614 2800
            </a>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-md text-center text-gray-600">
              © {year} Dulcería El Conejo Feliz v
              {import.meta.env.VITE_APP_VERSION}
            </p>
            <a href="/" className="text-md text-center text-gray-600">
              Aviso de privacidad
            </a>
          </div>
          <div className="flex flex-col justify-center">
            <a
              className="flex items-center"
              href="https://www.instagram.com/dulceriaelconejofeliz"
            >
              <FaSquareInstagram />
              @dulceriaelconejofeliz
            </a>
            <a
              className="text-md flex items-center"
              href="mailto:dudas@dulceriaelconejofeliz.com"
            >
              <MdEmail />
              dudas@dulceriaelconejofeliz.com
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}
