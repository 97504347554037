import SpinnerSvg from "../../assets/imgs/spinner.svg";
export function Loader({ message, open }: { message: string; open: boolean }) {
  return open ? (
    <div className="w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
      <div className="flex justify-center items-center h-full">
        <img src={SpinnerSvg} alt={message} />
      </div>
    </div>
  ) : null;
}
