import { useField } from "formik";
import { forwardRef } from "react";

function FileUploadInput({ label, accept, ...props }, ref) {
  const [, meta, helpers] = useField(props.name);

  const { setValue } = helpers;

  const handleChange = (event) => {
    const files = event.target?.files && (event.target.files[0] as File);

    if (!files) {
      setValue("");
      helpers.setError("No se seleccionó ningun archivo");
      return;
    }

    setValue(files);
  };

  return (
    <>
      <label
        className="mb-1 block text-sm font-medium text-gray-700"
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <input
        ref={ref}
        name={props?.name}
        accept={accept}
        onChange={handleChange}
        className={`inline-block w-full cursor-pointer rounded-md border border-gray-300 bg-slate-400 p-1 text-base text-gray-700 focus:outline-none ${
          meta.touched && meta.error
            ? "border-2 border-red-600 outline-1 outline-red-600"
            : null
        }`}
        type="file"
      />
      {meta.touched && meta.error ? (
        <p className="mt-1 text-sm text-red-600">{meta.error}</p>
      ) : null}
    </>
  );
}
export default forwardRef(FileUploadInput);
