import { Form, Formik } from "formik";
import { registerCustomer } from "../../services/customers";
import { SearchTextInput } from "../Form/SearchTextInput";
import { FormTextInput } from "../Form/TextInput";
import { ListFormInput } from "../Form/ListFormInput";
import {
  cajaNumbers,
  cfdiUse,
  mexicoStates,
  paymentMethods,
  regimenFiscales,
} from "../../shared/constants";
import FileUploadInput from "../Form/FileUploadInput";
import TicketExampleSrc from "../../assets/imgs/ticket.jpeg";
import { useRef, useState } from "react";
import { FormInitialValues, RegisterFormValues } from "../../types/customer";
import { RegisterSchema } from "../../sections/Customer/register.schema";
import { isNotEmptyObject } from "../../shared/functions";
import PrimaryButton from "../PrimaryButton";
import { ConfirmationFormDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

const minDate = dayjs().startOf("M");
const maxDateToday = dayjs();
export function RegisterForm({
  setIsLoading,
  setAlert,
  handleClickOpen,
  openModal,
  setOpenModal,
}) {
  const [isNewCustomer, setIsNewCustomer] = useState(true);
  const [dateValue, setDateValue] = useState<Dayjs>(maxDateToday);
  const cedulaRef = useRef<HTMLInputElement>(null);
  const ticketRef = useRef<HTMLInputElement>(null);

  const submit = async (values: RegisterFormValues, actions) => {
    if (isNewCustomer && !values.cedula) {
      actions.setFieldError("cedula", "Campo requerido para nuevos usuarios");
      return;
    }

    values.ticketDate = dateValue.toDate();

    if (values.ticketDate < minDate.toDate()) {
      actions.setFieldError(
        "ticketFecha",
        "Fecha incorrecta, solo se aceptan tickets del mes en curso",
      );
      return;
    }

    setIsLoading(true);

    try {
      await registerCustomer({
        ...values,
        ticketDate: values.ticketDate.toISOString(),
      });

      setAlert(() => ({
        open: true,
        message: "Información enviada correctamente 😃",
        type: "success",
      }));

      actions.resetForm();

      if (cedulaRef?.current) {
        cedulaRef.current.value = "";
      }

      if (ticketRef?.current) {
        ticketRef.current.value = "";
      }
    } catch (error) {
      setAlert(() => ({
        open: true,
        message: error.message,
        type: "error",
      }));
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 350);
    }
  };

  const onSelectDate = (newDate: Dayjs) => {
    setDateValue(newDate);
  };

  return (
    <Formik
      initialValues={FormInitialValues}
      validateOnChange={true}
      validationSchema={RegisterSchema}
      onSubmit={submit}
    >
      {({ isSubmitting, errors, touched }) => (
        <>
          <Form className="mt-5 grid grid-cols-1 gap-10 pt-2 md:grid-cols-2">
            <div>
              <SearchTextInput
                type="text"
                id="rfc"
                name="rfc"
                label="Busca tu RFC"
                setIsNewCustomer={setIsNewCustomer}
                setAlert={setAlert}
                setLoading={setIsLoading}
                placeholder="Busca tu RFC"
                autoComplete="on"
              />
            </div>
            <div>
              <FormTextInput
                id="name"
                type="text"
                name="name"
                label="Nombre/Razón social"
                placeholder="Escribe tu nombre o razón social"
                autoComplete="on"
              />
            </div>

            <div>
              <FormTextInput
                id="email"
                type="text"
                name="email"
                label="Correo electrónico"
                placeholder="Escribe tu correo electrónico"
                autoComplete="email"
              />
            </div>
            <div>
              <FormTextInput
                id="street"
                type="text"
                name="street"
                label="Calle"
                placeholder="Calle"
                autoComplete="billing street-address"
              />
            </div>
            <div>
              <FormTextInput
                type="text"
                id="exteriorNumber"
                name="exteriorNumber"
                label="Número exterior"
                placeholder="Número exterior"
                autoComplete="on"
              />
            </div>
            <div>
              <FormTextInput
                type="text"
                id="interiorNumber"
                name="interiorNumber"
                label="Número interior"
                placeholder="Número interior"
                autoComplete="on"
              />
            </div>
            <div>
              <FormTextInput
                id="zipCode"
                type="text"
                name="zipCode"
                label="Código postal"
                autoComplete="on"
                placeholder="Código postal"
              />
            </div>

            <div>
              <FormTextInput
                id="locality"
                type="text"
                name="locality"
                label="Colonia"
                placeholder="Colonia"
                autoComplete="address-line2"
              />
            </div>

            <div>
              <FormTextInput
                id="municipality"
                name="municipality"
                type="text"
                label="Municipio"
                placeholder="Municipio"
                autoComplete="address-level2"
              />
            </div>

            <div>
              <ListFormInput
                label="Estado"
                name="state"
                id="state"
                autoComplete="address-level2"
              >
                <option value="">Selecciona un estado</option>
                {mexicoStates.map((e) => (
                  <option key={e.value} value={e.value}>
                    {e.name}
                  </option>
                ))}
              </ListFormInput>
            </div>

            <ListFormInput
              label="Uso CFDI"
              name="cfdiUseCode"
              id="cfdiUseCode"
              autoComplete="on"
            >
              <option value="">Uso CFDI</option>
              {cfdiUse.map((e) => (
                <option key={e.value} value={e.value}>
                  {e.name}
                </option>
              ))}
            </ListFormInput>

            <ListFormInput
              label="Método de pago"
              name="paymentMethod"
              id="paymentMethod"
              autoComplete="on"
            >
              <option value="">Método de pago</option>
              {paymentMethods.map((e) => (
                <option key={e.value} value={e.value}>
                  {e.name}
                </option>
              ))}
            </ListFormInput>

            <div className="col-span-full">
              <FormTextInput
                id="ticketNumber"
                type="number"
                name="ticketNumber"
                label="# Ticket"
                placeholder="# Ticket"
              />
            </div>

            <div className="col-span-full">
              {/* <label
                className={
                  "block text-sm font-medium text-gray-700 " + labelStyle
                }
                htmlFor={props.id || props.name}
              >
                Fecha del ticket
              </label> */}
              <DatePicker
                label={"Fecha del ticket"}
                name="ticketFecha"
                sx={{
                  border: "0px solid",
                  backgroundColor: "white",
                  fieldset: {
                    border: "0px solid",
                  },
                  width: "100%",
                }}
                defaultValue={maxDateToday}
                value={dateValue}
                onChange={onSelectDate}
                minDate={minDate}
                format="DD/MM/YYYY"
                disableFuture
                maxDate={maxDateToday}
              />
            </div>

            <div className="col-span-full">
              <ListFormInput label="# Caja" name="cajaId">
                <option value="">Número de caja</option>
                {cajaNumbers.map((e) => (
                  <option key={e.code} value={e.code}>
                    {e.description}
                  </option>
                ))}
              </ListFormInput>
            </div>

            <div className="col-span-full">
              <ListFormInput label="Régimen fiscal" name="regimenFiscal">
                <option value="">Régimen fiscal</option>
                {regimenFiscales.map((e) => (
                  <option key={e.code} value={e.code}>
                    {`${e.code} - ${e.description}`}
                  </option>
                ))}
              </ListFormInput>
            </div>

            {isNewCustomer ? (
              <div className="col-span-full">
                <FileUploadInput
                  ref={cedulaRef}
                  label="Sube tu cédula de identificación fiscal"
                  name="cedula"
                  accept="application/pdf"
                />
              </div>
            ) : null}

            <div>
              <FileUploadInput
                ref={ticketRef}
                label="Sube una imágen de tu ticket"
                name="ticket"
                accept="image/jpeg, image/png, image/gif"
              />
            </div>

            <div>
              <p className="font-bold text-gray-500">
                Datos necesarios marcados en rojo:
              </p>
              <img src={TicketExampleSrc} alt="Ejemplo del ticket" />
            </div>
            <div className="col-span-full flex flex-col ">
              {errors && isNotEmptyObject(errors) && (
                <div className="col-span-full mb-5 mt-1 text-sm text-red-600">
                  <ul className="list-disc">
                    {Object.keys(errors)
                      .filter((key) => {
                        return errors[key] && touched[key];
                      })
                      .map((key) => {
                        return <li key={key}>{errors[key] as string}</li>;
                      })}
                  </ul>
                </div>
              )}
              <div>
                <PrimaryButton
                  className="ms-4 "
                  type="submit"
                  onClick={handleClickOpen}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Enviando..." : "Enviar"}
                </PrimaryButton>
              </div>
            </div>
          </Form>
          <ConfirmationFormDialog
            initialOpen={openModal}
            setOpen={setOpenModal}
            title="Verifica tus datos"
            message="¿Estas seguro de enviar estos datos?"
          />
        </>
      )}
    </Formik>
  );
}
