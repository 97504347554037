import { IoIosCloseCircle } from "react-icons/io";

export interface AlertComponentProps {
  open: boolean;
  message: string;
  type: "success" | "error" | "warning";
}
export function AlertComponent({ level, message, open, setOpen }) {
  const getLevelClass = (level) => {
    switch (level) {
      case "success":
        return "bg-green-100 text-green-800";
      case "error":
        return "bg-red-100 text-red-800";
      case "warning":
        return "bg-yellow-100 text-yellow-800";
      default:
        return "bg-blue-100 text-blue-800";
    }
  };

  return (
    <>
      {open && (
        <div
          className={
            "text-md mb-4 mt-3 flex w-full items-center justify-between rounded-lg bg-emerald-500 p-4  " +
            getLevelClass(level)
          }
          role="alert"
        >
          {message}

          <button className="text-xl" onClick={() => setOpen(!open)}>
            <IoIosCloseCircle />
          </button>
        </div>
      )}
    </>
  );
}
