import { useState } from "react";
import {
  AlertComponent,
  AlertComponentProps,
} from "../../components/Alert/Alert";
import { Loader } from "../../components/Loader/Loader";

import { RegisterForm } from "../../components/Register/Form";

export function Register() {
  const [alert, setAlert] = useState<AlertComponentProps>({
    open: false,
    message: "Información enviada correctamente, revisa tu correo electrónico",
    type: "success",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  return (
    <>
      <Loader open={isLoading} message="Enviando..." />

      <article className="min-w-full pb-10">
        <h2 className="text-center text-lg">
          Registra tus datos para solicitar tu factura electrónica.
        </h2>
        <p className="mt-2 text-center text-sm text-gray-500">
          ¡Gracias por elegirnos! Estamos comprometidos a facilitar tu
          experiencia de facturación. Por favor, completa el siguiente
          formulario para solicitar tu factura electrónica.
        </p>
        <p className="text-sm font-bold text-red-500">
          Recuerda no incluir tu régimen capital junto al RFC. Ejemplos:
        </p>
        <ul className="list-inside list-disc text-sm font-bold text-red-500">
          <li>S.A. DE C.V.</li>
          <li>S.C.</li>
        </ul>

        <AlertComponent
          message={alert.message}
          level={alert.type}
          open={alert.open}
          setOpen={setAlert}
        />

        <RegisterForm
          setIsLoading={setIsLoading}
          setAlert={setAlert}
          openModal={openModal}
          setOpenModal={setOpenModal}
          handleClickOpen={handleClickOpen}
        />
      </article>
    </>
  );
}
