import { GenericFieldHTMLAttributes, useField } from "formik";

export function ListFormInput({ label, ...props }) {
  const [field, meta] = useField(
    props as HTMLInputElement & GenericFieldHTMLAttributes,
  );
  return (
    <div>
      <label
        className="block text-sm font-medium text-gray-700"
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <select
        className={`block w-full rounded-md px-3 py-2 shadow-sm  focus:border-indigo-500 focus:ring-indigo-500 ${
          meta.touched && meta.error
            ? "border-2 border-red-600 outline-1 outline-red-600"
            : null
        }`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <p className="mt-1 text-sm text-red-600">{meta.error}</p>
      ) : null}
    </div>
  );
}
