export const mexicoStates = [
  { name: "AGUASCALIENTES", value: "AGUASCALIENTES" },
  { name: "BAJA CALIFORNIA", value: "BAJA CALIFORNIA" },
  { name: "BAJA CALIFORNIA SUR", value: "BAJA CALIFORNIA SUR" },
  { name: "CAMPECHE", value: "CAMPECHE" },
  { name: "CHIAPAS", value: "CHIAPAS" },
  { name: "CHIHUAHUA", value: "CHIHUAHUA" },
  { name: "CIUDAD DE MÉXICO", value: "CIUDAD DE MÉXICO" },
  { name: "COAHUILA", value: "COAHUILA" },
  { name: "COLIMA", value: "COLIMA" },
  { name: "DURANGO", value: "DURANGO" },
  { name: "ESTADO DE MÉXICO", value: "ESTADO DE MÉXICO" },
  { name: "GUANAJUATO", value: "GUANAJUATO" },
  { name: "GUERRERO", value: "GUERRERO" },
  { name: "HIDALGO", value: "HIDALGO" },
  { name: "JALISCO", value: "JALISCO" },
  { name: "MICHOACÁN", value: "MICHOACÁN" },
  { name: "MORELOS", value: "MORELOS" },
  { name: "NAYARIT", value: "NAYARIT" },
  { name: "NUEVO LEÓN", value: "NUEVO LEÓN" },
  { name: "OAXACA", value: "OAXACA" },
  { name: "PUEBLA", value: "PUEBLA" },
  { name: "QUERÉTARO", value: "QUERÉTARO" },
  { name: "QUINTANA ROO", value: "QUINTANA ROO" },
  { name: "SAN LUIS POTOSÍ", value: "SAN LUIS POTOSÍ" },
  { name: "SINALOA", value: "SINALOA" },
  { name: "SONORA", value: "SONORA" },
  { name: "TABASCO", value: "TABASCO" },
  { name: "TAMAULIPAS", value: "TAMAULIPAS" },
  { name: "TLAXCALA", value: "TLAXCALA" },
  { name: "VERACRUZ", value: "VERACRUZ" },
  { name: "YUCATÁN", value: "YUCATÁN" },
  { name: "ZACATECAS", value: "ZACATECAS" },
];

export const cfdiUse = [
  { name: "G01 - Adquisición de mercancías", value: "G01" },
  { name: "G02 - Devoluciones, descuentos o bonificaciones", value: "G02" },
  { name: "G03 - Gastos en general", value: "G03" },
  { name: "I01 - Construcciones", value: "I01" },
  {
    name: "I02 - Mobiliario y equipo de oficina por inversiones",
    value: "I02",
  },
  { name: "I03 - Equipo de transporte", value: "I03" },
  { name: "I04 - Equipo de computo y accesorios", value: "I04" },
  {
    name: "I05 - Dados, troqueles, moldes, matrices y herramental",
    value: "I05",
  },
  { name: "I06 - Comunicaciones telefónicas", value: "I06" },
  { name: "I07 - Comunicaciones satelitales", value: "I07" },
  { name: "I08 - Otra maquinaria y equipo", value: "I08" },
  {
    name: "D01 - Honorarios médicos, dentales y gastos hospitalarios",
    value: "D01",
  },
  {
    name: "D02 - Gastos médicos por incapacidad o discapacidad",
    value: "D02",
  },
  { name: "D03 - Gastos funerales", value: "D03" },
  { name: "D04 - Donativos", value: "D04" },
  {
    name: "D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
    value: "D05",
  },
  { name: "D06 - Aportaciones voluntarias al SAR", value: "D06" },
  { name: "D07 - Primas por seguros de gastos médicos", value: "D07" },
  {
    name: "D08 - Gastos de transportación escolar obligatoria",
    value: "D08",
  },
  {
    name: "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
    value: "D09",
  },
  {
    name: "D10 - Pagos por servicios educativos (colegiaturas)",
    value: "D10",
  },
  { name: "S01 - Sin efectos fiscales", value: "S01" },
  { name: "CP01 - Pagos", value: "CP01" },
  { name: "CN01 - Nómina", value: "CN01" },
];

export const paymentMethods = [
  { name: "01 - Efectivo", value: "01" },
  { name: "02 - Cheque nominativo", value: "02" },
  { name: "03 - Transferencia electrónica de fondos", value: "03" },
  { name: "04 - Tarjeta de crédito", value: "04" },
  { name: "05 - Monedero electrónico", value: "05" },
  { name: "06 - Dinero electrónico", value: "06" },
  { name: "08 - Vales de despensa", value: "08" },
  { name: "12 - Dación en pago", value: "12" },
  { name: "13 - Pago por subrogación", value: "13" },
  { name: "14 - Pago por consignación", value: "14" },
  { name: "15 - Condonación", value: "15" },
  { name: "17 - Compensación", value: "17" },
  { name: "23 - Novación", value: "23" },
  { name: "24 - Confusión", value: "24" },
  { name: "25 - Remisión de deuda", value: "25" },
  { name: "26 - Prescripción o caducidad", value: "26" },
  { name: "27 - A satisfacción del acreedor", value: "27" },
  { name: "28 - Tarjeta de débito", value: "28" },
  { name: "29 - Tarjeta de servicios", value: "29" },
  { name: "30 - Aplicación de anticipos", value: "30" },
  { name: "31 - Intermediario pagos", value: "31" },
  { name: "99 - Por definir", value: "99" },
];

export const regimenFiscales = [
  {
    code: "601",
    description: "General de Ley Personas Morales",
  },
  {
    code: "603",
    description: "Personas Morales con Fines no Lucrativos",
  },
  {
    code: "605",
    description: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
  },
  {
    code: "606",
    description: "Arrendamiento",
  },
  {
    code: "607",
    description: "Régimen de Enajenación o Adquisición de Bienes",
  },
  {
    code: "608",
    description: "Demás ingresos",
  },
  {
    code: "610",
    description:
      "Residentes en el Extranjero sin Establecimiento Permanente en México",
  },
  {
    code: "611",
    description: "Ingresos por Dividendos (socios y accionistas)",
  },
  {
    code: "612",
    description:
      "Personas Físicas con Actividades Empresariales y Profesionales",
  },
  {
    code: "614",
    description: "Ingresos por intereses",
  },
  {
    code: "615",
    description: "Régimen de los ingresos por obtención de premios",
  },
  {
    code: "616",
    description: "Sin obligaciones fiscales",
  },
  {
    code: "620",
    description:
      "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  },
  {
    code: "621",
    description: "Incorporación Fiscal",
  },
  {
    code: "622",
    description: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  },
  {
    code: "623",
    description: "Opcional para Grupos de Sociedades",
  },
  {
    code: "624",
    description: "Coordinados",
  },
  {
    code: "625",
    description:
      "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  },
  {
    code: "626",
    description: "Régimen Simplificado de Confianza",
  },
];

export const cajaNumbers = [
  { code: "NVC1", description: "NVC1" },
  { code: "NVC2", description: "NVC2" },
  { code: "NVC3", description: "NVC3" },
];

export const rfcRegex =
  /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/;
