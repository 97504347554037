import * as Sentry from "@sentry/react";
import axiosInstance from "../shared/axios";
import { RegisterFormValues } from "../types/customer";

export async function getCustomerInformation(rfc: string) {
  if (!rfc) return;
  try {
    const response = await axiosInstance.get(`/customers/${rfc.trim()}`);

    const { data } = response;
    return data.data;
  } catch (error) {
    if (error.response) {
      console.warn("Error usuario:", error.message);
    } else {
      console.warn("Error de red", error.message);
    }
    return null;
  }
}

export async function registerCustomer(values: RegisterFormValues) {
  const infoToSend = new FormData();
  Object.keys(values).forEach((key) => {
    if (values[key]) infoToSend.append(key, values[key]);
  });

  const controller = new AbortController();

  const signalId = setTimeout(() => {
    controller.abort();
  }, 30_000); // 20 segundos

  try {
    await axiosInstance({
      url: "/customers",
      method: "post",
      signal: controller.signal,
      data: infoToSend,
    });
  } catch (error) {
    Sentry.captureException(error);
    if (error.code && error.code === "ERR_CANCELED") {
      throw new Error("Se agotó el tiempo de espera para la petición");
    }

    if (error.response) {
      console.warn("Error usuario:", error.message);
      throw new Error(
        error.response?.data?.message ||
          "Error al guardar los datos, vuelve a intentarlo",
      );
    }

    throw new Error(
      "Ocurrió un error inesperado, vuelve a intentarlo o comunicate con nosotros",
    );
  } finally {
    clearTimeout(signalId);
  }
}
