import { Link } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";

export function Welcome() {
  return (
    <article className="text-justify">
      <h2 className="text-md text-wrap font-bold">
        En Dulcería El Conejo Feliz, endulzamos tus días con los mejores dulces
        y ahora, ¡facilitamos tu experiencia de facturación electrónica!
      </h2>
      <p className="mt-3">
        Estimado cliente, se informa que todas las compras realizadas en tienda
        y que requieran ser facturadas, deberan ser solicitadas{" "}
        <strong className="underline decoration-red-900">
          el día en que realizo su compra, en el siguiente link
          www.dulceriaelconejofeliz.com
        </strong>{" "}
        ya que de lo contrario nuestro sistema no podra facturar tickets de
        fechas anteriores. Agradecemos su compresion y reiteramos nuestro
        compromiso de brindarle un mejor servicio.
      </p>
      <p className="mt-3 font-bold">
        Fundamento Legal: Artículo 39 del Reglamento del Código Fiscal de la
        Federación vigente y Regla 2.7.1.21 de la Resolución Miscelánea Fiscal
        2024.
      </p>

      <div className="space mt-5 flex justify-center">
        <Link
          to="/clientes/registro"
          className="flex items-center rounded bg-titleSecondary px-7 py-4 text-center font-normal text-white shadow-md transition-colors hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50"
        >
          ¡Solicitar factura!
          <MdChevronRight />
        </Link>
      </div>
    </article>
  );
}
