// axios.ts
import axios, { AxiosInstance } from "axios";

// Configuración de la instancia base de Axios
const baseURL = import.meta.env.VITE_API_URL; // Reemplaza con tu URL base

// Crear una instancia de Axios con configuraciones predeterminadas
const axiosInstance: AxiosInstance = axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

// Interceptor para solicitudes
axiosInstance.interceptors.request.use(
  (config) => {
    // Aquí puedes agregar lógica antes de enviar una solicitud, por ejemplo, agregar un token de autenticación
    // config.headers.Authorization = `Bearer ${tuToken}`;
    return config;
  },
  (error) => {
    // Manejo de errores de solicitud
    return Promise.reject(error);
  },
);

// Interceptor para respuestas
axiosInstance.interceptors.response.use(
  (response) => {
    // Manejar respuestas exitosas
    return response;
  },
  (error) => {
    // Manejo de errores de respuesta
    // Aquí puedes agregar lógica para manejar errores globales, como errores de autenticación
    return Promise.reject(error);
  },
);

export default axiosInstance;
