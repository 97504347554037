import { GenericFieldHTMLAttributes, useField } from "formik";

export function FormTextInput({ label, type, labelStyle = "", ...props }) {
  const [field, meta, helpers] = useField(
    props as HTMLInputElement & GenericFieldHTMLAttributes,
  );

  const { setValue } = helpers;

  const handleChange = (e) => {
    setValue(e.target.value.toUpperCase());
  };

  return (
    <>
      <label
        className={"block text-sm font-medium text-gray-700 " + labelStyle}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <input
        {...field}
        className={`block w-full rounded-md border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${
          meta.touched && meta.error
            ? "border-2 border-red-600 outline-1 outline-red-600"
            : null
        }`}
        type={type}
        onChange={handleChange}
        {...props}
      />
      {meta.touched && meta.error ? (
        <span className="m-0 text-sm text-red-600">{meta.error}</span>
      ) : null}
    </>
  );
}
